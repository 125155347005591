<template>
  <div class="app-container">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="form" label-width="150px" class="mt20">
        <el-form-item label="用户名："> {{ form.name }} </el-form-item>
        <el-form-item label="员工编号：">{{ form.staffCode }}</el-form-item>
        <el-form-item label="手机号：">{{ form.phoneNumber }}</el-form-item>
        <el-form-item label="邮箱：">{{ form.email }}</el-form-item>
        <el-form-item label="角色：">
          <!-- <div class="role-box" @click="openDialog">
          <div
            class="role-detail"
            id="roleContent"
            v-text="allRoleText()"
            placeholder="请选择"
          ></div>
          <span
            class="el-select__caret el-input__icon el-icon-arrow-down"
            :class="{'is-reverse':roleDialog}"
          ></span>
        </div> -->
          {{ form.roleNameList.join('、') }}
        </el-form-item>
        <el-form-item label="客户下组织权限：" prop="orgAuth">
          <div class="session1" ref="shopBox">
            <div class="shop-content" @click="showTree">
              <ul class="shop-name">
                <li v-for="(item, index) in checkNodes" :key="index">
                  {{ item.name }}<i class="icon-delete" @click.stop="deleteNode(item)">×</i>
                </li>
              </ul>
              <span
                class="el-select__caret el-input__icon el-icon-arrow-down"
                :class="{ 'is-reverse': treeIsShow }"
              ></span>
              <!-- <div v-show="errorOrg" class="el-form-item__error">请选择</div> -->
            </div>
            <div class="treeBox mb20" v-show="treeIsShow" style="display: none">
              <div class="tree-content">
                <el-input placeholder="请输入客户组织名称" v-model="filterText"> </el-input>
                <el-tree
                  node-key="orgcode"
                  :data="shopsTree"
                  :props="defaultProps"
                  :default-expanded-keys="orgcode"
                  ref="tree"
                  :filter-node-method="filterNode"
                  :expand-on-click-node="false"
                  :highlight-current="true"
                  show-checkbox
                >
                </el-tree>
              </div>
              <div class="dialog-btn borderTop">
                <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
                <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="back">取消</el-button>
          <el-button type="primary" @click="submitForm">确认</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="角色管理" :visible.sync="roleDialog" width="531px" :before-close="handleClose">
      <div class="flex alignCenter">
        <div class="role-left">
          <div v-for="(item, index) in roleList" v-show="!item.addRight">
            <div class="checktitle">新零售-{{ item.pname }}</div>
            <el-radio
              class="rediocontain"
              v-model="item.radioValue"
              v-for="(ele, key) in item.roleList"
              :label="ele.rid"
              >{{ ele.rname }}
            </el-radio>
          </div>
        </div>
        <div class="transfer">
          <el-button @click="addRole" :disabled="addBtn">添加 ></el-button>
          <el-button @click="cancelSelect" :disabled="!selectList.length"> 取消</el-button>
        </div>
        <div class="role-right">
          <div v-for="(item, index) in roleList" v-show="item.addRight">
            <div class="checktitle">新零售-{{ item.pname }}</div>
            <el-radio class="rediocontain" v-model="selectList[index]" :label="item.pid">
              <span v-text="selectName(item)"></span>
            </el-radio>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitRole">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPlatRoles, getOrgShopTree, updateUserList } from 'src/api/legacy'

export default {
  data() {
    var validateOrgAuth = (rule, value, callback) => {
      if (!this.orgcode.length) {
        callback(new Error('请选择'))
      } else {
        callback()
      }
    }
    return {
      form: {},
      // orgcode: [],
      rules: {
        roles: [
          {
            required: true,
            message: '请选择',
            trigger: 'click',
          },
        ],
        orgAuth: [
          {
            required: true,
            validator: validateOrgAuth,
            trigger: 'change',
          },
        ],
      },
      filterText: '',
      selectList: [],
      roleList: [],
      errorOrg: false,
      roleDialog: false,
      treeIsShow: false, //树显示
      checkNodes: [], //默认组织树名
      getTreeOrgcode: [], //选中树组织数据
      shopsTree: [],
      defaultProps: {
        label: 'name',
        children: 'sublist',
      },
    }
  },
  computed: {
    orgcode() {
      return this.checkNodes.length ? this.checkNodes.map((v) => v.orgcode) : []
    },
    addBtn() {
      return this.roleList.every((item) => {
        return item.radioValue === '' || item.addRight
      })
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  created() {
    this.form = JSON.parse(localStorage.getItem('userInfo')) || {}
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      // await this.getRoleList()
      await this.OrgShopTree()
      //绑定用户
      const roleids = this.form.roleIdList
      const list = JSON.parse(JSON.stringify(this.roleList))
      list.forEach((v) => {
        return v.roleList.map((e, index) => {
          if (roleids.includes(e.rid)) {
            v.radioValue = e.rid
            v.addRight = true
          }
        })
      })
      this.form.roles = [
        ...list.filter((v) => {
          return v.radioValue && v.addRight === true
        }),
      ]
      //组织权限
      this.checkNodes = this.form.orgNameList.map((item) => ({
        name: item,
      }))
      this.$refs.tree.setCheckedKeys(this.form.orgCodeList)
      const nodes = this.$refs.tree.getCheckedNodes()
      this.checkNodes = this.filter(nodes)
    },
    //查询各平台角色
    getRoleList() {
      getPlatRoles().then((res) => {
        if (res && res.status === 0) {
          if (res.status == 0) {
            this.roleList = res.data.map((v) => {
              return {
                ...v,
                radioValue: '',
                addRight: false,
              }
            })
          }
        }
      })
    },
    allRoleText() {
      return (
        this.form.roles && this.form.roles.map((v) => (v.pname ? `${v.pname}： ${this.selectName(v)}` : '')).join('、')
      )
    },
    addRole() {
      //添加
      this.roleList.forEach((item) => {
        if (item.radioValue) {
          item.addRight = true
        }
      })
    },
    cancelSelect() {
      //取消
      this.roleList.forEach((item) => {
        if (this.selectList.includes(item.pid)) {
          item.addRight = false
          item.radioValue = ''
        }
      })
      this.selectList = []
    },
    selectName(item) {
      if (item.radioValue) {
        return item.roleList.find((v) => {
          return v.rid === item.radioValue
        }).rname
      }
    },
    submitRole() {
      this.roleDialog = false
      let list = JSON.parse(JSON.stringify(this.roleList))
      this.form.roles = list.filter((v) => {
        return v.radioValue && v.addRight === true
      })
    },
    hideAll() {
      //隐藏树组织
      this.treeIsShow = false
    },
    OrgShopTree() {
      //获取树文件数据
      let data = {
        cid: this.form.cid,
      }
      return getOrgShopTree(data).then((res) => {
        if (res.data && res.status === 0) {
          res.data.disabled = false
          this.shopsTree = [res.data]
        }
      })
    },
    getOrgcode() {
      const nodes = this.$refs.tree.getCheckedNodes()
      this.checkNodes = this.filter(nodes)
      this.treeIsShow = false
      this.validateOrg()
    },
    validateOrg(val, text) {
      if (!this.orgcode.length) {
        this.errorOrg = true
        return false
      } else {
        this.errorOrg = false
        return true
      }
    },
    filter(checkNodes) {
      const orgcodeArr = checkNodes.map((n) => n.orgcode)
      const index = orgcodeArr.findIndex((item) => item === '0')
      orgcodeArr.splice(index, 1)
      return checkNodes.filter((v) => !orgcodeArr.includes(v.parentCode))
    },
    filterNode(value, data, node) {
      // 如果什么都没填就直接返回
      if (!value) return true
      // 如果传入的value和data中的label相同说明是匹配到了
      const reg = new RegExp(value, 'i')
      if (reg.test(data.name)) {
        return true
      }
      // 否则要去判断它是不是选中节点的子节点
      return this.checkBelongToChooseNode(value, data, node)
    },
    // 判断传入的节点是不是选中节点的子节点
    checkBelongToChooseNode(value, data, node) {
      const level = node.level
      // 如果传入的节点本身就是一级节点就不用校验了
      if (level === 1) {
        return false
      }
      // 先取当前节点的父节点
      let parentData = node.parent
      // 遍历当前节点的父节点
      let index = 0
      while (index < level - 1) {
        // 如果匹配到直接返回
        if (parentData.data.name.indexOf(value) !== -1) {
          return true
        }
        // 否则的话再往上一层做匹配
        parentData = parentData.parent
        index++
      }
      // 没匹配到返回false
      return false
    },
    deleteNode(node) {
      const index = this.checkNodes.findIndex((v) => {
        return node === v
      })
      this.checkNodes.splice(index, 1)
      this.$refs.tree.setCheckedNodes(this.checkNodes)
      this.validateOrg()
    },
    openDialog() {
      this.roleDialog = true
      this.form.roles.length &&
        this.form.roles.forEach((v) => {
          const i = this.roleList.findIndex((r) => r.pid === v.pid)
          this.roleList[i].radioValue = v.radioValue
          this.roleList[i].addRight = true
        })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    //显示树组织
    treeClick() {
      this.filterText = ''
      this.$refs.tree.setCheckedNodes(this.checkNodes)
      this.treeIsShow = true
    },
    showTree() {
      this.treeClick()
    },
    back() {
      this.$router.go(-1)
    },
    submitForm() {
      console.log(this.orgcode)
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.updateUser()
        } else {
          return false
        }
      })
    },
    updateUser() {
      //修改
      let orgSet = this.orgcode.filter((item) => item !== '0')
      // this.form.ugid = this.form.ugid === '' ? 0 : this.form.ugid
      let params = {
        cid: this.form.cid,
        uid: this.form.id,
        // roleIdSet: this.form.roles.map(v => v.radioValue),
        orgSet,
      }
      updateUserList(params).then((res) => {
        if (res.data) {
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          setTimeout(() => {
            this.back()
          }, 1000)
        } else {
          this.$message({
            message: '失败',
            type: 'success',
          })
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.navtitle {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  font-size: 14px;
}
.active {
  color: #5c76cc;
  cursor: pointer;
}
.role-detail {
  padding: 0 15px !important;
  box-sizing: border-box;
}
.el-input__icon {
  position: absolute;
  top: 0;
  right: 0;
}
.treeBox {
  width: 468px;
  left: 0;
  top: auto;
  margin-top: 10px;
}
.el-tree {
  width: 90%;
  max-height: 268px;
  overflow-y: auto;
  padding: 0 20px;
}

.session1 .shop-content {
  /* margin-top: 5px; */
  position: relative;
  width: 468px;
}

.shop-content .shop-name {
  width: 468px;
  min-height: 36px;
  line-height: 36px;
  padding: 0px 24px 0px 10px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}
.shop-name li {
  height: 22px;
  line-height: 22px;
  padding: 0 20px 0 8px;
  display: inline-block;
  background-color: #fff;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  position: relative;
}

li .icon-delete {
  position: absolute;
  right: 0;
  padding: 0 8px 0 10px;
}
</style>